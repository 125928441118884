.infoIcon {
    background-repeat: no-repeat;
    background-position: center center;
    margin-right: 60px;
    margin-top: 0px;
    vertical-align: middle;
    color: rgb(43, 43, 43);
    border-radius: 100%;
    z-index: 0;
    width: 100px;
    height: 100px;
    transform: scale(2);
    transition: all .15s cubic-bezier(.11,.65,1,1.69);
}

.githubIcon {
    background-repeat: no-repeat;
    background-position: center center;
    margin-right: 60px;
    margin-top: 0px;
    vertical-align: middle;
    color: rgb(43, 43, 43);
    border-radius: 100%;
    z-index: 0;
    width: 100px;
    height: 100px;
    transform: scale(1.6);
    transition: all .15s cubic-bezier(.11,.65,1,1.69);
}

.instagramIcon {
    background-repeat: no-repeat;
    background-position: center center;
    margin-right: 0px;
    margin-top: 0px;
    vertical-align: middle;
    color: rgb(43, 43, 43);
    border-radius: 100%;
    z-index: 0;
    width: 100px;
    height: 100px;
    transform: scale(2);
    transition: all .15s cubic-bezier(.11,.65,1,1.69);
}

.infoIcon:hover {
    transform: scale(4);
}

.githubIcon:hover {
    transform: scale(3.35);
}

.instagramIcon:hover {
    transform: scale(4);
}

.leftAlign {
    margin-left: 100px;
}